import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-modal';
import { isMobile, isTablet, isBrowser, isSafari } from 'react-device-detect';

import Layout from "../components/layout";
import SEO from "../components/seo";
import ParallaxImage from "../components/parallaxImage.js";
import PortfolioCarousel from "../components/portfolio-carousel";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { gsap } from "../vendor/gsap/src/index.js";
import { CustomEase } from "../vendor/gsap/src/CustomEase.js";
import { ScrollTrigger } from "../vendor/gsap/src/ScrollTrigger.js";
import "../pages/voor-wie.scss";
// import { LeakRemoveTwoTone } from "@material-ui/icons";
import NextgenContainer from "../components/nextgen-container";

gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTrigger);

const customerTypeQuery = graphql`
  {
    gcms {
      customerTypes(orderBy: priority_ASC, where: {NOT: {slug: "start-pakket"}}) {
        slug
        customerTypeName
        description { html }
        priority
        moreLinkText
        containerColor
        parallaxDisplayType
        parallaxAnimationType
        parallaxImage1
        parallaxImage2
        parallaxImage3
        modules {
          moduleTooltip
          moduleName
          slug
        }
      }
      titles {
        indicator
        value
        richValue { html }
      }
      metaDescriptions {
        indicator
        value
      }
      backgroundImages {
        indicator
        deviceType
        backgroundImage
        parallaxDisplayType
        parallaxAnimationType
        parallaxImage1
        parallaxImage2
        parallaxImage3
      }
    }
  }
`;

let customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '20px 20px 35px',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    borderRadius: '20px',
    backgroundColor: '#00357d',
    border: 0,
    color: 'white'
  }
};

if (isMobile && isTablet === false) {
  customStyles.content.width = '300px';
}

if (isMobile && isTablet) {
  customStyles.content.width = '600px';
}

// let metaDescriptionValue = '';

// let forWhoTitle = '';
let listTitle = '';
let listSubTitle = '';

let backgroundImage = '';

let mobileImage = '';
let tabletImage = '';
let desktopImage = '';

let previousScroll = 0;

const refsArray = [];

const cleanup = () => {
  document.documentElement.style.overflow = 'unset';
}

const CustomerTypePage = () => {
  const {
    gcms: { customerTypes, titles, backgroundImages }
  } = useStaticQuery(customerTypeQuery);

  for (const image of backgroundImages) {
    if (image.indicator === 'for-who-buttons-background') {
      if (image.deviceType === 'mobile' && (image.backgroundImage != null && image.backgroundImage !== '')) {
        mobileImage = image.backgroundImage;
      }
      if (image.deviceType === 'tablet' && (image.backgroundImage != null && image.backgroundImage !== '')) {
        tabletImage = image.backgroundImage;
      }
      if (image.deviceType === 'desktop' && (image.backgroundImage != null && image.backgroundImage !== '')) {
        desktopImage = image.backgroundImage;
      }
    }
  }

  if (isMobile && isTablet === false && isBrowser === false) {
    if (mobileImage !== '') {
      backgroundImage = mobileImage;
    } else if (desktopImage !== '') {
      backgroundImage = desktopImage;
    } else if (tabletImage !== '') {
      backgroundImage = tabletImage;
    }
  } else if (isMobile && isTablet && isBrowser === false) {
    if (tabletImage !== '') {
      backgroundImage = tabletImage;
    } else if (mobileImage !== '') {
      backgroundImage = mobileImage;
    } else if (desktopImage !== '') {
      backgroundImage = mobileImage;
    }
  } else {
    if (desktopImage !== '') {
      backgroundImage = desktopImage;
    } else if (tabletImage !== '') {
      backgroundImage = tabletImage;
    } else if (mobileImage !== '') {
      backgroundImage = mobileImage;
    }
  }

  if (backgroundImage === 'test' || backgroundImage === 'tablet') backgroundImage = '';

  const [modalIsOpen, setIsOpen] = useState(false);
  const [mobile, setMobile] = useState(null);
  const [moduleData, setModuleData] = useState(null);
  const [moduleSlug, setModuleSlug] = useState(null);
  const [safari, setSafari] = useState(null);
  const groupsRef = useRef(null);

  for (const title of titles) {
    // if (title.indicator === 'for-who-start-title' && title.richValue && title.richValue.html) {
    //   forWhoTitle = title.richValue.html;
    // }
    if (title.indicator === 'for-who-list-title') {
      listTitle = title.value;
    }
    if (title.indicator === 'for-who-list-subtitle') {
      listSubTitle = title;
    }
  }

  // for (const metaDescription of metaDescriptions) {
  //   if (metaDescription.indicator === 'modules-meta-description') {
  //     metaDescriptionValue = metaDescription.value;
  //   }
  // }

  const handleOpenModal = (module) => {
    setModuleSlug(module.slug);
    setModuleData(module);
    setIsOpen(true);
    previousScroll = window.scrollY;
  }

  const handleCloseModal = () => {
    setModuleSlug(null);
    setModuleData(null);
    setIsOpen(false);
  }

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ block: "center" });
    } else {
      if (ref && ref.offsetTop != null && ref.offsetHeight) {
        // if (isMobile) {
        //   window.scrollTo(0, ref.offsetTop + ref.offsetHeight);
        // } else {
        //   window.scrollTo(0, ref.offsetTop + ref.offsetHeight + 500);
        // }
        window.scrollTo(0, ref.offsetTop + document.querySelector('.header-container').offsetHeight);
      }
    }
  }

  const onScroll = () => {
    if (modalIsOpen) {
      if (previousScroll !== window.scrollY) {
        handleCloseModal();
      }
      previousScroll = window.scrollY;
    }
  }

  if (typeof window !== "undefined") {
    window.addEventListener('scroll', onScroll, true);
  }

  useEffect(() => {
    let elements = document.querySelectorAll('.row-fadeIn');
    let animations = [];
    elements.forEach((container, i) => {
      animations.push(gsap.to(container, 1, {
        opacity: 1,
        y: 0,
        duration: .1,
        ease: "power4.in",
        scrollTrigger: {
          id: `fadeIn_trigger_${i}`,
          trigger: container,
          start: "top 90%",
          end: "bottom 0%"
        }
      }));
    });


    Modal.setAppElement('body');
    if (isBrowser) {
      setMobile(true);
    } else {
      setMobile(false);
    }


    if (isSafari === true) {
      setSafari(true);
    } else {
      setSafari(false);
    }

    return () => {
      elements.forEach((container, i) => {
        animations[i].kill(true);
        ScrollTrigger.getById(`fadeIn_trigger_${i}`).kill(true);
      });
    }
  }, [mobile]);

  return (
    <Layout onScroll={onScroll()} >
      <SEO title="Voor wie" description="Zo'n 150 reisgerelateerde bedrijven maken intussen gebruik van de TravelSpirit tools. Wij stellen ook voor jou graag een pakket op maat samen!" urlParams="voor-wie" />
      <img className="print print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
      <div className="for-who-container">
        {/* <div className="for-who-start-container container">
          <div className="row inner-container">
            <div className="col-sm">
              <button className="text-button" onClick={() => scrollToRef(groupsRef)}>
                <div dangerouslySetInnerHTML={{ __html: forWhoTitle }} />
              </button>
              <button className="arrow bounce" onClick={() => scrollToRef(groupsRef)}>
                <div className="mobile-arrow"></div>
              </button>
            </div>
          </div>
        </div> */}
        <div className="customer-type-container container airplane-wrapper">
          <div className="summary-container container" style={{ backgroundImage: `url(${backgroundImage})` }} ref={groupsRef}>
            <h1 className="title">{listTitle}</h1>
            <div className="subtitle">
              <p className="inline">{listSubTitle.value} </p>
              {(listSubTitle.richValue && listSubTitle.richValue.html) &&
                <div className="html-text inline" dangerouslySetInnerHTML={{ __html: listSubTitle.richValue.html }} />
              }
            </div>

            <div className="quote-container">
              <blockquote>Maatwerk in het samenstellen van reizen kost tijd, maar met TravelSpirit en de Visual Tour Builder (VTB) liggen onze reisvoorstellen binnen de kortste termijn bij onze reizigers. - Chris Freitag, Van Verre Reizen</blockquote>
            </div>

            <div id="summary-menu-container">
              <ul className="summary-menu">
                {customerTypes.map((customerType, i) => {
                  return <li key={customerType.slug + 'ref'}>
                    <button className="orange-button" onClick={() => scrollToRef(refsArray[i])}>{customerType.customerTypeName}</button>
                  </li>
                })}
              </ul>
            </div>
          </div>
          <div className="customer-journey-container">
            {customerTypes.map((customerType, i) => {
              return <div id={customerType.slug + "-container"} className={customerType.containerColor + "-container container"} ref={ref => {
                refsArray[i] = ref;
              }} key={customerType.slug}>
                {mobile === true ? (
                  <div className="print-ignore row justify-content-between row-fadeIn">
                    {i % 2 === 1 && (
                      <div className={"col-sm-5 " + customerType.slug + "-fadeIn parallax-container"}>
                        <ParallaxImage slug={customerType.slug} safariCheck={safari} timeline={customerType.parallaxAnimationType} displayType={customerType.parallaxDisplayType} images={customerType} />
                      </div>
                    )}
                    <div className={"col-sm-5 " + customerType.slug + "-fadeIn"}>
                      <div className="row">
                        {/* <span className="counter">{('0' + (i + 1)).slice(-2)}</span> */}
                        <div dangerouslySetInnerHTML={{ __html: customerType.description.html }} />
                        {customerType.modules && customerType.modules.length > 0 && <span className="modules-list">
                          <h2>Meest gebruikte modules:</h2>
                          <hr />
                          <ul className="module-list">
                            {customerType.modules.map((module, index) => {
                              if (index > 5) return null
                              return <li className="module-list-item" key={module.slug}>
                                <button onClick={() => handleOpenModal(module)}>
                                  {module.moduleName}
                                </button>
                              </li>
                            })}
                          </ul>
                        </span>}
                      </div>
                      <div className="row">
                        {/* <Link className="module-reference" key={customerType.slug} to={`/voor-wie/${customerType.slug}`}>{customerType.moreLinkText}
                          <div className="icon baseline">
                            <ArrowRightAltIcon />
                          </div>
                        </Link> */}
                        <Link className="module-reference-button" key={customerType.slug} to={`/voor-wie/${customerType.slug}`}>{customerType.customerTypeName} modules</Link>
                      </div>
                    </div>
                    {i % 2 === 0 && (
                      <div className={"col-sm-5 " + customerType.slug + "-fadeIn parallax-container"}>
                        <ParallaxImage slug={customerType.slug} safariCheck={safari} timeline={customerType.parallaxAnimationType} displayType={customerType.parallaxDisplayType} images={customerType} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="print-ignore row justify-content-between row-fadeIn">
                    <div className={"col-sm-5 " + customerType.slug + "-fadeIn"}>
                      <div className="row">
                        {/* <span className="counter">{('0' + (i + 1)).slice(-2)}</span> */}
                        <div dangerouslySetInnerHTML={{ __html: customerType.description.html }} />
                        {customerType.modules && customerType.modules.length > 0 && <span>
                          <h2>Meest gebruikte modules:</h2>
                          <hr />
                          <ul className="module-list">
                            {customerType.modules.map((module, index) => {
                              if (index > 5) return null
                              return <li className="module-list-item" key={module.slug}>
                                <button onClick={() => handleOpenModal(module)}>
                                  {module.moduleName}
                                </button>
                              </li>
                            })}
                          </ul>
                        </span>}
                      </div>
                      <div className="row">
                        {/* <Link className="module-reference" key={customerType.slug} to={`/voor-wie/${customerType.slug}`}>{customerType.moreLinkText}
                          <div className="icon baseline">
                            <ArrowRightAltIcon />
                          </div>
                        </Link> */}
                        <Link className="module-reference-button" key={customerType.slug} to={`/voor-wie/${customerType.slug}`}>{customerType.customerTypeName} modules</Link>
                      </div>
                    </div>
                    <div className={"col-sm-5 " + customerType.slug + "-fadeIn parallax-container"}>
                      <ParallaxImage slug={customerType.slug} safariCheck={safari} timeline={customerType.parallaxAnimationType} displayType={customerType.parallaxDisplayType} images={customerType} />
                    </div>
                  </div>
                )}
                <div className="print print-container">
                  <div className="row">
                    {/* <span className="counter">{('0' + (i + 1)).slice(-2)}</span> */}
                    <div dangerouslySetInnerHTML={{ __html: customerType.description.html }} />
                    {customerType.modules && customerType.modules.length > 0 && <span>
                      <h2>Meest gebruikte modules:</h2>
                      <hr />
                      <ul className="module-list">
                        {customerType.modules.map((module, index) => {
                          if (index > 5) return null
                          return <li className="module-list-item" key={module.slug}>
                            <button onClick={() => handleOpenModal(module)}>
                              {module.moduleName}
                            </button>
                          </li>
                        })}
                      </ul>
                    </span>}
                  </div>
                  <div className="row">
                    {/* <Link className="module-reference" key={customerType.slug} to={`/voor-wie/${customerType.slug}`}>{customerType.moreLinkText}
                      <div className="icon baseline">
                        <ArrowRightAltIcon />
                      </div>
                    </Link> */}
                    <Link className="module-reference-button" key={customerType.slug} to={`/voor-wie/${customerType.slug}`}>{customerType.customerTypeName} modules</Link>
                  </div>
                </div>
                <div className="print print-container">
                </div>
              </div>
            })}
          </div>
          <div className="final-container container">
            <NextgenContainer titles={titles} />
          </div>
        </div>
        <div className="portfolio-carousel">
          <PortfolioCarousel />
        </div>
        <Modal isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          style={customStyles}
        >
          {moduleData && moduleData.moduleTooltip && moduleData.moduleName && (
            <div>
              <h1>{moduleData.moduleName}</h1>
              <p>{moduleData.moduleTooltip}</p>
              <Link className="modal-module-reference" key={moduleSlug} onClick={() => cleanup()} to={`/modules/${moduleSlug}`}>Meer over {moduleData.moduleName}
                <div className="icon baseline">
                  <ArrowRightAltIcon />
                </div>
              </Link>
            </div>
          )}
        </Modal>
      </div>
    </Layout>
  )
}

export default CustomerTypePage;