import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { isSafari } from 'react-device-detect';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import "./portfolio-carousel.scss"

const logosQuery = graphql`
  {
    allDataJson {
      edges {
        node {
          logos {
            class
            url
            name
            site
          }
        }
      }
    }
  }
`;

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

let eventAdded = false;
let type = "initial";
let initialSettings = {
  type: "initial",
  infinite: true,
  speed: 2000,
  slidesToShow: 10,
  slidesToScroll: 10,
  autoplay: true,
  autoplaySpeed: 6000,
};

const PortfolioCarousel = () => {
  const [settings, setSettings] = useState(initialSettings);
  const [showVideo, setShowVideo] = useState(false);
  let [logos, setLogos] = useState(null);

  const updateSettings = () => {
    if (type !== "mobile" && window.innerWidth < 481) {
      type = "mobile";
      initialSettings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 6000,
      };
      setSettings(initialSettings);
    } else if (type !== "small-tablet" && window.innerWidth > 480 && window.innerWidth < 701) {
      type = "small-tablet";
      initialSettings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 6000,
      };
      setSettings(initialSettings);
    } else if (type !== "tablet" && window.innerWidth > 700 && window.innerWidth < 1200) {
      type = "tablet";
      initialSettings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 6,
        slidesToScroll: 6,
        autoplay: true,
        autoplaySpeed: 6000,
      };
      setSettings(initialSettings);
    } else if (type !== "initial" && window.innerWidth > 1199) {
      type = "initial";
      initialSettings = {
        type: "initial",
        infinite: true,
        speed: 2000,
        slidesToShow: 10,
        slidesToScroll: 10,
        autoplay: true,
        autoplaySpeed: 6000,
      };
      setSettings(initialSettings);
    }
  }

  const data = useStaticQuery(logosQuery);

  if (logos != null) {
    logos = logos.slice(0, 50); //limit logos for less DOM nodes (google speed test)
  }

  if (typeof window !== "undefined") {
    if (eventAdded === false) {
      if (!window.hasResizeEvent) {
        window.addEventListener('resize', updateSettings);
      }
      window.hasResizeEvent = true;
      eventAdded = true;
    }
  }

  const onScroll = () => {
    if (showVideo === false) {
      setShowVideo(true);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onMouseMove);
    }
  }

  const onMouseMove = () => {
    if (showVideo === false) {
      setShowVideo(true);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onMouseMove);
    }
  }

  if (typeof window !== "undefined") {
    window.addEventListener('scroll', onScroll, true);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('touchmove', onMouseMove);
  }

  useEffect(() => {
    updateSettings();
    if (isSafari === true) {
      setLogos(shuffleArray(data.allDataJson.edges[0].node.logos).map((logo) => {
        return (
          <a className={logo.class} key={logo.name} href={logo.site} rel="noopener noreferrer" target="_blank">
            <img src={"https://media.travelspirit.nl" + logo.url.replace('webp', 'png').replace('/carousel/smw/', '/carousel/sm/')} alt={"carousel-image-" + logo.name} loading="lazy" />
          </a>
        );
      }));
    } else {
      setLogos(shuffleArray(data.allDataJson.edges[0].node.logos).map((logo) => {
        return (
          <a className={logo.class} key={logo.name} href={logo.site} rel="noopener noreferrer" target="_blank">
            <img src={"https://media.travelspirit.nl" + logo.url} alt={"carousel-image-" + logo.name} loading="lazy" />
          </a>
        );
      }));
    }

    return function cleanup() {
      window.hasResizeEvent = false;
      window.removeEventListener('resize', updateSettings);
      eventAdded = false;
    }
  }, [data.allDataJson.edges]);

  return (
    <>
      <div className="carousel-container container">
        <div className="row">
          <h1>Zij gingen je al voor:</h1>
        </div>
        {showVideo === true && <div className="carousel">
          <Slider {...settings}>
            {logos}
          </Slider>
        </div>}
        <div className="row">
          <div className="portfolio-reference-container">
            <Link className="portfolio-reference" to="/portfolio">Bekijk ons volledige portfolio
              <div className="icon baseline">
                <ArrowRightAltIcon />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default PortfolioCarousel
